import React, { MouseEvent } from "react";
import styles from "./button.module.css";
import classnames from "classnames";
import { raleway } from "fonts";

interface Props {
  href?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  type?: string;
  value?: string;
  disabled?: boolean;
}

const Button = ({ href, children, className, onClick, type, value, disabled }: Props) => {
  if (type && (type == "submit" || type == "button")) {
    return <input onClick={onClick} value={value} className={classnames(className, styles.button, raleway.className)} type={type} disabled={disabled} />;
  } else {
    return (
      <a className={classnames(className, styles.button, raleway.className)} href={href} onClick={onClick}>
        {children}
      </a>
    );
  }
};

export default Button;
