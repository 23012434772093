"use client";

import { useState, forwardRef } from "react";
import { useMailchimp, Status } from "./hook";
import classnames from "classnames";

import styles from "./subscribe.module.css";
import Button from "../button/button";

interface Props {
  defaultValue?: string;
  className?: string;
  userId?: string|null;
}
export type Ref = HTMLInputElement;

const Subscribe = forwardRef<Ref, Props>(({defaultValue, className, userId}, ref) => {
  const [form, setForm] = useState({ email: "", user_id: userId||"", status: Status.idle });

  const { subscribe, status, error, value } = useMailchimp("https://virna.us21.list-manage.com/subscribe/post?u=981b14bda9fc035ecf0214e3b&amp;id=763d77ebed&amp;f_id=008ba2e1f0");

  // TODO: https://auth0.com/docs/customize/email/send-email-invitations-for-application-signup

  const handleInputChange = (event: { target: any }) => {
    const target = event.target;
    const value = target.value;

    setForm((form) => ({
      ...form,
      email: value,
    }));
  };

  const handleSubmit = (e) => {
    console.log('handlesubmit', form, e)
    e.preventDefault();

    if (form.email === "") return;

    subscribe({
      EMAIL: form.email,
      USERID: form.user_id,
      tags: "320581",
      "b_981b14bda9fc035ecf0214e3b_763d77ebed": null
    });

    setForm((form) => ({
      ...form,
      status,
    }));
  };

  if (value) {
    return <p>Thank you for joining!</p>;
  }

  let classNames = classnames(styles.subscribe, status === Status.error ? styles.error : "", className ? className : "");

  return (
    <form className={classNames} onSubmit={handleSubmit}>
      <p className={styles.errorContainer}>{error}</p>
      <div className={styles.gradientWrapper}>
        <div className={styles.gradient1} />
        <div className={styles.gradient2} />
        <input type="email" placeholder="Your email" name="EMAIL" className={styles.email} id="email" required onChange={handleInputChange} ref={ref} defaultValue={defaultValue} />
        <input type="hidden" value={form.user_id} name="USERID" />
        <input type="hidden" name="tags" value="320581" />
        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <input type="text" name="b_981b14bda9fc035ecf0214e3b_763d77ebed" tabIndex={-1} readOnly />
      </div>
      <Button onClick={handleSubmit} value="Join" className={styles.input} type="submit" disabled={status === Status.loading} />
    </form>
  );
});

export default Subscribe;
